/* barlow-100 - latin-ext_latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-100italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 100;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-100italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-200 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-300 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-200italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 200;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-200italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-300italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-300italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-regular - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-500 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-500italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-500italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-600 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-600italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-600italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-700 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-700italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-700italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-800 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-800italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-800italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-900 - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  
  /* barlow-900italic - latin-ext_latin */
  @font-face {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 900;
    src: url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/barlow-v11-latin-ext_latin/barlow-v11-latin-ext_latin-900italic.svg#Barlow') format('svg'); /* Legacy iOS */
  }
  