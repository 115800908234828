@import "./colors.scss";

.product-starter { background-color: $weare-product-starter !important; }
.product-pro { background-color: $weare-product-pro !important; }
.product-enterprise { background-color: $weare-product-enterprise !important; }

.navbar {
  margin-top: 35px;
}

.plan {
  font-weight: bold;
  text-transform: uppercase;
}

.content-blob {
  background-color: $weare-light-gray;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 20px;

  h1, h2, h3, h4, h5 {
    background-color: $weare-dark-gray;
    color: $weare-white;
    text-align: center;
    padding: 15px;
    font-weight: bold;
  }

  .content-blob-inner {
    padding: 20px 30px 20px 30px;
  }

}

.page-teaser {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
}

.page-teaser-content {
  position: absolute;
  top: 0px;
  width: 100%;

  h1, h2, h3, h4, h5 {
    margin-bottom: 1em;
  }

}

.form-group {
  margin-bottom: 10px;
}

p {
  margin: 0 0 15px 0;
}

.hero.text_headlines {
	overflow-x: hidden;
	padding-top: 80px;
}

.hero .bg_image {
	position: relative;
}

.hero .bg_image img {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 767px) {
  .hero .bg_image img {
    width: 126%;
    left: -16%;
  }
}

.hero .bg_image .content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 992px) {
  .hero.text_headlines .content {
    position: relative;
  }  
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1380px;
  padding: 0 30px;
}


.vertical_centered {
	display: flex;
	align-items: center;
}

// .row > * {
// 	box-sizing: border-box;
// 	flex-shrink: 0;
// 	// width: 100%;
// 	max-width: 100%;
// 	padding-right: calc(var(--bs-gutter-x)*.5);
// 	padding-left: calc(var(--bs-gutter-x)*.5);
// 	margin-top: var(--bs-gutter-y);
// }

.hero.text_headlines .headline.big {
	font-size: 4rem;
	text-transform: uppercase;
	margin-bottom: 50px;
}

.headline {
	line-height: 1.15;
}

main section {
	margin-bottom: 50px;
}

.hero .bg_image {
	position: relative;
}

.hero .bg_image figure {
	margin: 0;
}

.hero .desktop {
	display: block;
}

@media (max-width: 768px) {
  .hero .desktop {
    display: none;
  }

  .hero.text_headlines .headline.big h1, .hero.text_headlines .headline.big h2, .hero.text_headlines .headline.big h3, .hero.text_headlines .headline.big h4, .hero.text_headlines .headline.big h5, .hero.text_headlines .headline.big h6 {
    font-size: 56px;
  }

}

@media (min-width: 768px) {
  .container-fluid {
    padding: 0 50px;
  }
}

@media (min-width: 992px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-24 {
    flex: 0 0 auto;
    width: 100%;
  }  
}

@media (min-width: 768px) {
  .shop_3 .shop_3_teaser .shop_item {
    margin-bottom: 70px;
  }  
}

.required-field:after {
  content:" *";
}

@media (max-width: 768px) {
  .hero.text_headlines .headline.big h1, .hero.text_headlines .headline.big h2, .hero.text_headlines .headline.big h3, .hero.text_headlines .headline.big h4, .hero.text_headlines .headline.big h5, .hero.text_headlines .headline.big h6 {
    font-size: 40px;
  }
}

.required-fields-info {
  text-align: right;
  font-size: 0.8em;
}