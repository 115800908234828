@import "~bootstrap/scss/bootstrap";
@import "./Barlow.scss";
@import "./colors.scss";

h1 { font-size: 64px; font-weight: bold; text-transform: uppercase; }
h2 { font-size: 48px; font-weight: bold; text-transform: uppercase; }
h3 { font-size: 34px; font-weight: bold; text-transform: uppercase; }
h4 { font-size: 24px; font-weight: bold; text-transform: uppercase; }
h5 { font-size: 20px; font-weight: bold; text-transform: uppercase; }

body {
  margin: 0;
  padding: 0;
  color: $weare-primary;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-weight: bold;
}