@import "./colors.scss";

.shop_3 .shop_3_teaser {
	display: flex;
	margin: 0 -15px;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h1, .shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h2, .shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h3, .shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h4, .shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h5, .shop_3 .shop_3_teaser .shop_item .shop_item_inner .text h6 {
	font-size: 1.5rem;
	margin-bottom: 10px;
}

@media (min-width: 768px) {
    .shop_3 .shop_3_teaser .shop_item {
        margin-bottom: 70px;
    }
}

.shop_3 .shop_3_teaser .shop_item {
	flex-basis: 100%;
	margin: 0 15px 50px;
	text-align: center;
	transition: flex-basis .4s ease;
	text-transform: uppercase;

	&.text-left {
		text-align: left;
	}
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner {
	border-radius: 16px;
	overflow: hidden;
	height: 100%;
	background: #f4f4f4;
	padding: 0 50px 60px;
	display: flex;
	flex-direction: column;
	transition: margin .4s ease,height .4s ease, padding .4s ease;
}

.shop_3 .shop_3_teaser .shop_item {
	.model.starter {
		background: $weare-product-starter;
	}

	.model.pro {
		background: $weare-product-pro;
	}

	.model.enterprise {
		background: $weare-product-enterprise;
	}

	.model.default {
		background: $weare-dark-gray;
	}

}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .model {
	margin: 0 -50px;
	padding: 20px 50px;
	color: #fff;
	margin-bottom: 25px;
	font-size: 1.75rem;
	font-weight: bold;
	text-align: center;
	transition: margin .4s ease;
}


.shop_3 .shop_3_teaser .shop_item .shop_item_inner .dauer {
	height: 20px;
}

p:last-child {
	margin: 0;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .price {
	font-size: 3rem;
	font-weight: 500;
	line-height: 1;
	margin: 5px 0;
	transition: font-size .4s ease;
	text-transform: none;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .billing {
	margin-bottom: 30px;
	text-transform: none;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .text {
	line-height: 2.2;
	transition: line-height .4s ease,font-weight .4s ease;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .link_wrapper {
	margin-top: auto;
	padding-top: 50px;
}

.shop_3 .shop_3_teaser .shop_item .shop_item_inner .link_wrapper a.btn_bg, button.btn_bg {
	display: block;
	padding: 14px 20px 15px;
	transition: padding .4s ease,border .4s ease,color .4s ease,background .4s ease;
}


@media (min-width: 768px) {
    a.btn_bg, button.btn_bg {
        padding: 14px 20px 15px;
    }
}

a.btn_bg, button.btn_bg {
    font-weight: 500;
    background: #0cd2c1;
    border-radius: 7px;
    padding: 14px 20px 15px;
    line-height: 1;
    display: inline-block;
    color: #fff;
  }

a, button {
    transition: background .4s ease,color .4s ease,border .4s ease;
}

a.btn_bg:hover, button.btn_bg:hover {
    background-color: $weare-primary;
	color: $weare-white;
}



a {
	color: #141450;
	text-decoration: none;
}

a {
	background-color: transparent;
}


  

@media (min-width: 992px) {
	.shop_3 .shop_3_teaser .shop_item.selected-plan {
		flex-basis: 60%;
	  }

	.shop_3 .shop_3_teaser .shop_item:hover {
		.shop_item_inner {
			padding: 0 55px 60px;
			
			.model {
				margin-left: -55px;
				margin-right: -55px;
			}

			.text {
				line-height: 2.3;
				font-weight: 500;
			}
		}

		flex-basis: 105%;

		&.selected-plan {
			flex-basis: 63%;
		}

	}
}

@media (max-width:768px) {
	.shop_3 .shop_3_teaser .shop_item .shop_item_inner .model {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 992px) {
	.shop_3 .shop_3_teaser {
		flex-wrap: wrap;
	}	
}
